import type { SVGProps } from "react";

export function InflowIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			aria-label="Inflow icon"
			viewBox="0 0 400 123"
			width="400"
			height="123"
			fill="none"
			{...props}
		>
			<title>Inflow icon</title>
			<path d="M140 3.60016C129.733 6.93349 124.133 11.0668 120.267 18.0002C118.267 21.4668 118 28.4002 117.6 71.6002L117.2 121.333H125.867H134.667V86.6668V52.0002H143.333H152V44.0002V36.0002H143.2H134.267L135.067 30.5335C136 23.6002 138.667 20.6668 146.133 18.6668L152 16.9335V8.53349C152 3.86682 151.6 0.000156432 151.067 0.13349C150.4 0.266823 145.467 1.73349 140 3.60016Z" />
			<path d="M171.333 10.5335L164.667 12.9335L164.267 67.0668L164 121.333H172H180V64.6668C180 33.4668 179.6 8.00016 179.067 8.13349C178.4 8.13349 175.067 9.20016 171.333 10.5335Z" />
			<path d="M0 66.6668V121.333H8.66667H17.3333V66.6668V12.0002H8.66667H0V66.6668Z" />
			<path d="M31.3333 36.2668C30.9333 37.2001 30.8 56.6668 30.9333 79.3335L31.3333 120.667L39.7333 121.067L48 121.467V86.0001V50.6668H60.5333C71.2 50.6668 73.6 51.0668 77.3333 53.6001C87.0666 60.2668 87.3333 61.0668 87.7333 92.8001L88.2666 121.333H96.1333H104V92.2668C104 76.4001 103.333 61.0668 102.667 58.4001C100.533 50.9335 93.4666 42.5335 86 38.8001C79.7333 35.4668 78.1333 35.3335 55.7333 34.9335C36.8 34.5335 31.8666 34.8001 31.3333 36.2668Z" />
			<path d="M217.733 37.0668C199.867 43.4668 190.133 58.0001 190.133 78.6668C190.133 88.0001 190.667 91.3334 193.467 97.3334C198.267 107.6 204.133 113.867 213.067 118.267C223.333 123.333 239.733 123.6 250.267 118.8C258.4 115.2 267.067 106.4 271.067 97.6001C275.2 88.6668 275.2 68.6668 271.067 59.8668C267.2 51.3334 259.467 43.0668 251.467 39.0668C242.4 34.5334 227.467 33.6001 217.733 37.0668ZM244.133 53.7334C261.733 62.8001 261.6 94.8001 244 103.6C229.467 111.067 213.2 104.267 208.667 88.8001C206.133 80.4001 206.133 75.0668 208.667 67.8668C214.133 52.6668 229.867 46.2668 244.133 53.7334Z" />
			<path d="M274.933 37.6001C275.333 38.5335 278.4 48.2668 281.867 59.3335C285.333 70.2668 291.2 88.8001 294.8 100.4L301.467 121.467L310.4 121.067L319.333 120.667L327.867 92.9335C332.667 77.7335 336.933 65.3335 337.333 65.3335C337.733 65.3335 341.867 77.7335 346.667 93.0668L355.333 120.667L364.267 121.067L373.067 121.467L375.067 115.067C376.267 111.6 382 93.2001 388 74.2668C394 55.3335 399.2 38.9335 399.6 37.8668C400.133 36.4001 398.533 36.0001 391.067 36.0001H381.733L374.8 59.6001C370.933 72.6668 367.2 85.6001 366.4 88.4001C365.6 91.0668 364.533 93.0668 364.133 92.5335C363.733 92.1335 359.467 79.4668 354.667 64.2668L345.867 36.6668L337.333 36.2668L328.667 35.8668L320.133 63.6001C315.467 78.8001 311.2 91.8668 310.667 92.4001C309.867 93.3335 305.467 79.2001 294.4 40.9335L292.933 36.0001H283.6C276.8 36.0001 274.533 36.4001 274.933 37.6001Z" />
		</svg>
	);
}
