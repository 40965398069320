import { z } from "zod";

export const bankAccountSchema = z.object({
	id: z.string(),
	accountOwnerName: z.string(),
	iban: z.string(),
	bic: z.string(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
});

export type BankAccount = z.infer<typeof bankAccountSchema>;
