import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { usePayments } from "../../api/usePayments";
import { numberFormatter } from "../../lib/number";
import { dashPaymentsRoute } from "../../pages/dash/payments";
import { dashPaymentRoute } from "../../pages/dash/payments/payment";
import { NoData } from "../charts/no-data";
import { Loading } from "../loading";
import { StatusPill } from "../payment/status-pill";
import { DataCard } from "./data-card";

export function RecentPayments() {
	const { payments, isLoading } = usePayments({
		page: 1,
		limit: 5,
	});

	let body: JSX.Element;

	if (isLoading) {
		body = <Loading className="w-full py-28" />;
	} else if (payments.length === 0) {
		body = <NoData className="my-4 h-52" />;
	} else {
		body = (
			<div className="flex flex-col divide-y divide-y-300">
				{payments
					.sort((a, b) =>
						dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1,
					)
					.slice(0, 5)
					.map((payment) => {
						return (
							<Link
								key={payment.id}
								to={dashPaymentRoute.to}
								params={{ id: payment.id }}
								className="flex justify-between items-center py-1 text-sm text-neutral-700"
							>
								<div className="flex flex-col">
									<span className="font-medium">
										{numberFormatter(payment.amount / 100, {
											currency: payment.currency,
											style: "currency",
										})}
									</span>
									<span className="text-neutral-500 whitespace-nowrap truncate">
										{dayjs(payment.createdAt).format("MMM DD H:mm a")}
										{payment.customerEmail ? ` - ${payment.customerEmail}` : ""}
									</span>
								</div>
								<StatusPill status={payment.status} />
							</Link>
						);
					})}
			</div>
		);
	}

	return (
		<DataCard
			title="Recent payments"
			viewAllHref={dashPaymentsRoute.to}
			className="col-span-12 2xl:col-span-12"
		>
			{body}
		</DataCard>
	);
}
