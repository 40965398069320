import { cn } from "../lib/cn";

type Props = {
	className?: string;
};

export function NotFound({ className }: Props) {
	return (
		<div
			className={cn(
				"w-full h-screen flex flex-col space-y-1 justify-center items-center",
				className,
			)}
		>
			<h1 className="text-neutral-700">404 - Not Found</h1>
			<span className="text-sm text-neutral-500 max-w-sm text-center px-6">
				The page you are looking for does not exist. It might have been moved or
				deleted.
			</span>
		</div>
	);
}
