import { PrivyProvider, getAccessToken } from "@privy-io/react-auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, createRootRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { ErrorPage } from "../components/error";
import { Loading } from "../components/loading";
import { NotFound } from "../components/not-found";
import { ModalProvider } from "../providers/modal";

dayjs.extend(utc);

export const queryClient = new QueryClient();

export const rootRoute = createRootRoute({
	component: RootLayout,
	beforeLoad: async ({ location }) => {
		const accessToken = await getAccessToken();

		return {
			accessToken,
			location,
		};
	},
	errorComponent: () => <ErrorPage />,
	notFoundComponent: () => <NotFound />,
	pendingComponent: () => <Loading className="w-screen h-screen" />,
});

function RootLayout() {
	return (
		<PrivyProvider
			appId={import.meta.env.VITE_PRIVY_APP_ID || ""}
			config={{
				loginMethods: ["email"],
				embeddedWallets: {
					createOnLogin: "users-without-wallets",
				},
			}}
		>
			<QueryClientProvider client={queryClient}>
				<div className="text-sm md:text-base">
					<ModalProvider>
						<Outlet />
					</ModalProvider>
				</div>
			</QueryClientProvider>
		</PrivyProvider>
	);
}
