import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import {
	createRoute,
	redirect,
	useLoaderData,
	useNavigate,
	useSearch,
} from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import z from "zod";
import {
	supportedCountriesOptions,
	useSuspenseSupportedCountries,
} from "../api/useSupportedCountries";
import { Button } from "../components/ui/button";
import { countries } from "../data/countries";
import { fetcher } from "../lib/fetcher";
import { getUser } from "../lib/get-user";
import { queryClient, rootRoute } from "./__root";

const merchantSearchParamsSchema = z.object({
	next: z.string().optional(),
});

const formSchema = z.object({
	merchant: z.string().min(3, "Company name is too short"),
	country: z.string(),
});

type FormValues = z.infer<typeof formSchema>;

export const merchantRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/merchant",
	component: MerchantPage,
	beforeLoad: async ({ search }) => {
		return {
			next: search.next,
		};
	},
	loader: async ({ context }) => {
		const user = await getUser(context.accessToken);

		if (!user || !context.accessToken) {
			throw redirect({
				to: "/login",
				search: {
					next: context.next || "/",
				},
			});
		}

		if (user.legalName !== null) {
			throw redirect({
				to: context.next || "/",
			});
		}

		await queryClient.ensureQueryData(supportedCountriesOptions());

		return {
			user,
			token: context.accessToken,
		};
	},
	validateSearch: merchantSearchParamsSchema,
});

function MerchantPage() {
	const navigate = useNavigate({ from: "/merchant" });
	const search = useSearch({ from: "/merchant" });
	const { token } = useLoaderData({ from: "/merchant" });
	const { supportedCountries } = useSuspenseSupportedCountries();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		resolver: zodResolver(formSchema),
	});
	const { mutateAsync: createMerchant } = useMutation({
		mutationKey: ["create-merchant"],
		mutationFn: async (payload: FormValues) => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/merchant`,
				z.any(),
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						legalName: payload.merchant,
						country: payload.country,
					}),
				},
			);
		},
	});

	async function onSubmit(data: FormValues) {
		await createMerchant(data);

		navigate({
			to: search.next || "/",
		});
	}

	return (
		<div className="h-screen w-full flex items-center justify-center">
			<div className="w-full max-w-xl flex flex-col space-y-8">
				<div className="border-b border-neutral-300 pb-2">
					<h1 className="text-lg font-semibold">
						Setting up your merchant account
					</h1>
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="w-full flex flex-col space-y-6"
				>
					<div className="flex flex-col space-y-4">
						<div className="flex flex-col space-y-1">
							<div className="flex items-center justify-between">
								<label htmlFor="merchant" className="text-sm text-neutral-700">
									Comapany Name
								</label>
								{errors.merchant && (
									<span className="text-sm text-red-500">
										{errors.merchant.message}
									</span>
								)}
							</div>
							<input
								id="merchant"
								type="text"
								placeholder="My Company Ltd."
								className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out"
								{...register("merchant")}
							/>
						</div>
						<div className="flex flex-col space-y-1">
							<div className="flex items-center justify-between">
								<label htmlFor="country" className="text-sm text-neutral-700">
									Country
								</label>
								{errors.country && (
									<span className="text-sm text-red-500">
										{errors.country.message}
									</span>
								)}
							</div>
							<select
								id="country"
								className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out"
								{...register("country", { required: true })}
							>
								{supportedCountries?.map((iso) => (
									<option key={iso} value={iso}>
										{countries[iso]} ({iso})
									</option>
								))}
							</select>
						</div>
					</div>
					<Button
						type="submit"
						className="ml-auto w-fit px-2 py-1 border border-neutral-200 shadow-sm text-sm rounded-md text-neutral-700 transition-colors duration-200 hover:border-neutral-300 hover:text-neutral-800"
					>
						Submit
					</Button>
				</form>
			</div>
		</div>
	);
}
