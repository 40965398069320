import dayjs from "dayjs";
import { useMemo } from "react";
import { usePayments } from "./usePayments";

export function useChartData() {
	const { payments, ...query } = usePayments({
		status: ["SETTLED"],
		page: 1,
		limit: 1_000_000,
	});

	const data = useMemo(() => {
		if (!payments) {
			return [];
		}

		const from = dayjs().subtract(3, "month").startOf("day");
		const to = dayjs().endOf("day");

		const dataPoints = dayjs(to).diff(from, "day") + 1;

		const data: {
			date: Date;
			value: number;
		}[] = [];

		for (let i = 0; i < dataPoints; i++) {
			const date = dayjs.utc(to).subtract(i, "day").startOf("day").toDate();

			const paymentsInInterval = payments.filter((event) =>
				dayjs(event.createdAt).isSame(date, "day"),
			);

			const value = paymentsInInterval
				.map((event) => event.amount)
				.reduce((acc, amount) => acc + amount, 0);

			data.push({
				date,
				value,
			});
		}

		return data.reverse();
	}, [payments]);

	return {
		chart: data,
		...query,
	};
}
