import { zodResolver } from "@hookform/resolvers/zod";
import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from "ibantools";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useBankAccount } from "../../api/useBankAccount";
import { fetcher } from "../../lib/fetcher";
import { queryClient } from "../../pages/__root";
import { Button } from "../ui/button";

const formValuesSchema = z.object({
	accountOwnerName: z.string().min(1, "Account Holder is required"),
	iban: z
		.string()
		.min(1, "IBAN is required")
		.refine(
			(iban) => {
				const formattedIBAN = electronicFormatIBAN(iban);

				if (formattedIBAN === null) {
					return false;
				}

				return isValidIBAN(formattedIBAN);
			},
			{
				message: "Invalid IBAN",
			},
		),
	bic: z.string().min(1, "BIC is required").refine(isValidBIC, {
		message: "Invalid BIC",
	}),
	// file: z.any(), //instanceof(File),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function EditBankDetails() {
	const [isEditing, setIsEditing] = useState(false);
	const [hasBankAccount, setHasBankAccount] = useState(false);
	const { bankAccount } = useBankAccount();
	const { mutate } = useMutation({
		mutationKey: ["update-bank-account"],
		mutationFn: async (data: FormValues) => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/merchant/bank`,
				z.any(),
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
					body: JSON.stringify(data),
				},
			);
		},
		onMutate: async (newMerchantBank) => {
			await queryClient.cancelQueries({ queryKey: ["bank-account"] });

			const prev = queryClient.getQueryData(["bank-account"]);

			queryClient.setQueryData(["bank-account"], () => ({
				...newMerchantBank,
				iban: newMerchantBank.iban.slice(-4),
			}));

			return { prev };
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(["bank-account"], context?.prev);

			alert("An error occurred updating your bank details. Please try again.");
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["bank-account"] });
		},
	});
	const { mutateAsync: deleteBankAccount } = useMutation({
		mutationKey: ["delete-bank-account"],
		mutationFn: async () => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/merchant/bank`,
				z.any(),
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
				},
			);
		},
		onMutate: async () => {
			await queryClient.cancelQueries({ queryKey: ["bank-account"] });

			const prev = queryClient.getQueryData(["bank-account"]);

			queryClient.setQueryData(["bank-account"], () => null);

			return { prev };
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(["bank-account"], context?.prev);

			alert("An error occurred deleting your bank details. Please try again.");
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["bank-account"] });
		},
	});
	const {
		handleSubmit,
		register,
		clearErrors,
		formState: { errors },
		reset,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
	});

	useEffect(() => {
		if (bankAccount) {
			setHasBankAccount(true);
			reset({
				accountOwnerName: bankAccount.accountOwnerName,
				iban: `••••${bankAccount.iban}`,
				bic: bankAccount.bic,
			});
		} else if (bankAccount === null) {
			setHasBankAccount(false);
			reset({
				accountOwnerName: "",
				iban: "",
				bic: "",
			});
		}
	}, [bankAccount, reset]);

	async function onSubmit(data: FormValues) {
		mutate(data);
		setIsEditing(false);
	}

	return (
		<div className="p-4 w-full flex flex-col space-y-1">
			<p className="font-medium">Bank Account</p>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="w-full flex flex-col space-y-4"
			>
				<div className="w-full grid grid-cols-2 gap-4">
					<div className="col-span-2 flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label
								htmlFor="accountOwnerName"
								className="text-sm text-neutral-700"
							>
								Account Holder
							</label>
							{errors.accountOwnerName && (
								<span className="text-sm text-red-500">
									{errors.accountOwnerName.message}
								</span>
							)}
						</div>
						<input
							id="accountOwnerName"
							type="text"
							disabled={!isEditing}
							placeholder="David Keller"
							className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out"
							{...register("accountOwnerName")}
						/>
					</div>
					<div className="flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label htmlFor="iban" className="text-sm text-neutral-700">
								IBAN
							</label>
							{errors.iban && (
								<span className="text-sm text-red-500">
									{errors.iban.message}
								</span>
							)}
						</div>
						<input
							id="iban"
							type="text"
							disabled={!isEditing}
							placeholder="IBAN"
							className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out"
							{...register("iban")}
						/>
					</div>
					<div className="flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label htmlFor="bic" className="text-sm text-neutral-700">
								BIC
							</label>
							{errors.bic && (
								<span className="text-sm text-red-500">
									{errors.bic.message}
								</span>
							)}
						</div>
						<input
							id="bic"
							type="text"
							disabled={!isEditing}
							placeholder="BIC"
							className="w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out"
							{...register("bic")}
						/>
					</div>
					{/* <div className="col-span-2 flex flex-col space-y-1">
            <label
              htmlFor="file"
              className={cn(
                "flex justify-center items-center text-sm text-neutral-700 h-12 border border-dashed border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out",
                !isEditing && "bg-neutral-50"
              )}
            >
              {((watch("file")?.length ?? 0) > 0 && watch("file")[0].name) ||
                "Bank Identity Statement"}
            </label>
            <input
              id="file"
              type="file"
              accept="application/pdf"
              disabled={!isEditing}
              placeholder="Bank Identity Statement"
              className="w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute"
              {...register("file")}
            />
          </div> */}
				</div>
				<div className="flex justify-end">
					{hasBankAccount ? (
						<Button
							color="red"
							onClick={async () => {
								await deleteBankAccount();
							}}
						>
							Delete
						</Button>
					) : isEditing ? (
						<div className="flex space-x-2">
							<Button
								color="red"
								onClick={async () => {
									clearErrors();
									setIsEditing(false);
								}}
							>
								Cancel
							</Button>
							<Button type="submit">Save</Button>
						</div>
					) : (
						<Button
							onClick={async () => {
								setIsEditing(true);
							}}
						>
							Edit
						</Button>
					)}
				</div>
			</form>
		</div>
	);
}
