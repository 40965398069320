import { zodResolver } from "@hookform/resolvers/zod";
import { getAccessToken } from "@privy-io/react-auth";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useMerchant } from "../../api/useMerchant";
import { useSupportedCountries } from "../../api/useSupportedCountries";
import { countries } from "../../data/countries";
import { cn } from "../../lib/cn";
import { fetcher } from "../../lib/fetcher";
import { queryClient } from "../../pages/__root";
import { Button } from "../ui/button";

const formValuesSchema = z.object({
	merchant: z.string().min(3),
	country: z.string(),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function EditBusinessDetails() {
	const [isEditing, setIsEditing] = useState(false);
	const { supportedCountries } = useSupportedCountries();
	const { merchant } = useMerchant({
		enabled: !supportedCountries,
	});
	const { mutate } = useMutation({
		mutationKey: ["update-merchant"],
		mutationFn: async (payload: FormValues) => {
			return fetcher(
				`${import.meta.env.VITE_API_URL}/dashboard/merchant`,
				z.any(),
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${await getAccessToken()}`,
					},
					body: JSON.stringify({
						legalName: payload.merchant,
						country: payload.country,
					}),
				},
			);
		},
		onMutate: async (newMerchant) => {
			await queryClient.cancelQueries({ queryKey: ["merchant"] });

			const prev = queryClient.getQueryData(["merchant"]);

			queryClient.setQueryData(["merchant"], () => newMerchant);

			return { prev };
		},
		onError: (_, __, context) => {
			queryClient.setQueryData(["merchant"], context?.prev);

			alert(
				"An error occurred updating your business details. Please try again.",
			);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ["merchant"] });
		},
	});
	const {
		handleSubmit,
		register,
		clearErrors,
		formState: { errors },
		reset,
	} = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		mode: "onSubmit",
		reValidateMode: "onChange",
	});

	useEffect(() => {
		if (merchant && supportedCountries) {
			reset({
				merchant: merchant.merchantName,
				country: merchant.country ?? "",
			});
		}
	}, [merchant, supportedCountries, reset]);

	async function onSubmit(data: FormValues) {
		mutate(data);
		setIsEditing(false);
	}

	return (
		<div className="p-4 w-full flex flex-col space-y-1">
			<p className="font-medium">Business Information</p>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="w-full flex flex-col space-y-4"
			>
				<div className="w-full grid grid-cols-2 gap-4">
					<div className="flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label htmlFor="merchant" className="text-sm text-neutral-700">
								Legal business name
							</label>
							{errors.merchant && (
								<span className="text-sm text-red-500">
									{errors.merchant.message}
								</span>
							)}
						</div>
						<input
							id="merchant"
							type="text"
							disabled={!isEditing}
							className={cn(
								"w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out",
								!merchant && "bg-neutral-200 border-none animate-pulse",
							)}
							{...register("merchant")}
						/>
					</div>
					<div className="flex flex-col space-y-1">
						<div className="flex items-center justify-between">
							<label htmlFor="country" className="text-sm text-neutral-700">
								Country
							</label>
							{errors.country && (
								<span className="text-sm text-red-500">
									{errors.country.message}
								</span>
							)}
						</div>
						<select
							id="country"
							disabled={!isEditing}
							className={cn(
								"w-full px-3 py-1.5 text-sm border border-neutral-300 hover:border-neutral-400 focus:border-neutral-400 outline-none rounded-md transition-colors duration-300 ease-in-out",
								!merchant && "bg-neutral-200 border-none animate-pulse",
							)}
							{...register("country", { required: true })}
						>
							{supportedCountries?.map((iso) => (
								<option key={iso} value={iso}>
									{countries[iso]} ({iso})
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="flex justify-end">
					{isEditing ? (
						<div className="flex space-x-2">
							<Button
								color="red"
								onClick={async () => {
									clearErrors();
									setIsEditing(false);
								}}
							>
								Cancel
							</Button>
							<Button type="submit">Save</Button>
						</div>
					) : (
						<Button
							onClick={async () => {
								setIsEditing(true);
							}}
						>
							Edit
						</Button>
					)}
				</div>
			</form>
		</div>
	);
}
