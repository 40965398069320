import { getAccessToken } from "@privy-io/react-auth";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { fetcher } from "../lib/fetcher";
import { bankAccountSchema } from "../schemas/bank-account";
import type { Options } from "./types";

type BankAccountOptions = Options<Awaited<ReturnType<typeof getBankAccount>>>;

async function getBankAccount() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/merchant/bank`,
		bankAccountSchema,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function bankAccountOptions(options: BankAccountOptions) {
	return {
		...options,
		queryKey: ["retry" in options ? "has-bank-account" : "bank-account"],
		queryFn: () => getBankAccount(),
		staleTime: 10 * 1000,
	};
}

export function useBankAccount(options: BankAccountOptions = {}) {
	const { data, ...query } = useQuery(bankAccountOptions(options));

	return {
		bankAccount: data,
		...query,
	};
}

export function useSuspenseBankAccount(options: BankAccountOptions = {}) {
	const { data, ...query } = useSuspenseQuery(bankAccountOptions(options));

	return {
		bankAccount: data,
		...query,
	};
}
