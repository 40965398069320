import { getAccessToken } from "@privy-io/react-auth";
import { createRoute } from "@tanstack/react-router";
import { balanceOptions, useSuspenseBalance } from "../../api/useBalance";
import { GrossRevenue } from "../../components/dash/gross-revenue";
import { RecentPayments } from "../../components/dash/recent-payments";
import { queryClient } from "../__root";
import { dashLayout } from "./_layout";

export const dashIndexRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/",
	component: IndexPage,
	loader: async () => {
		const accessToken = await getAccessToken();

		await queryClient.ensureQueryData(balanceOptions());

		return {
			accessToken,
		};
	},
});

function IndexPage() {
	const { balance, isLoading } = useSuspenseBalance();

	return (
		<div className="p-8 w-full flex flex-col space-y-4">
			<div className="flex justify-between items-center">
				<h1 className="font-semibold text-2xl">Dashboard</h1>
				{/* <span className="px-2 py-1 flex items-center space-x-2 border border-neutral-200 shadow-sm text-sm font-medium rounded-md text-neutral-700 transition-colors duration-200 hover:border-neutral-300 hover:text-neutral-800">
					Last {dayjs().diff(dayjs().subtract(3, "months"), "months")} months
				</span> */}
			</div>
			<div className="grid grid-cols-12 gap-6">
				<GrossRevenue />
				<div className="col-span-12 2xl:col-span-4 p-6 flex flex-col">
					<span className="text-neutral-600">Balance</span>
					{!isLoading ? (
						<span className="text-xl font-semibold text-neutral-900">
							{`${balance?.currencySymbol} ${balance?.readableAmount}`}
						</span>
					) : (
						<span className="text-xl font-semibold text-neutral-900">
							USDC 0
						</span>
					)}
				</div>
				<RecentPayments />
			</div>
		</div>
	);
}
