import { z } from "zod";

/**
TODO: type this properly

{
    "types": {
    "EIP712Domain": [
        {
        "name": "name",
        "type": "string"
        },
        {
        "name": "version",
        "type": "string"
        },
        {
        "name": "chainId",
        "type": "uint256"
        },
        {
        "name": "verifyingContract",
        "type": "address"
        }
    ],
    "Permit": [
        {
        "name": "owner",
        "type": "address"
        },
        {
        "name": "spender",
        "type": "address"
        },
        {
        "name": "value",
        "type": "uint256"
        },
        {
        "name": "nonce",
        "type": "uint256"
        },
        {
        "name": "deadline",
        "type": "uint256"
        }
    ],
    },
    "primaryType": "Permit",
    "domain": {
    "name": erc20name,
    "version": version,
    "chainId": chainid,
    "verifyingContract": tokenAddress
    },
    "message": {
    "owner": owner,
    "spender": spender,
    "value": value,
    "nonce": nonce,
    "deadline": deadline
    }
}
*/
export const eip2612Schema = z.object({
	domain: z.object({
		name: z.string(),
		version: z.string(),
		chainId: z.number(),
		verifyingContract: z.string(),
	}),
	primaryType: z.literal("Permit"),
	types: z.object({
		EIP712Domain: z.array(z.object({ name: z.string(), type: z.string() })),
		Permit: z.array(z.object({ name: z.string(), type: z.string() })),
	}),
	message: z.object({
		owner: z.string(),
		spender: z.string(),
		value: z.string(),
		nonce: z.number(),
		deadline: z.number(),
	}),
});
