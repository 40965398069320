import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useOnClickOutside } from "usehooks-ts";
import { z } from "zod";
import { useCurrencies } from "../../api/useCurrencies";
import { CircleXIcon } from "../../assets/circle-x";
import { cn } from "../../lib/cn";
import { objectEntries } from "../../lib/object";
import {
	type PaymentStatus,
	paymentStatusWithExpiredSchema,
} from "../../schemas/payment";
import { Button } from "../ui/button";

export function CurrencyFilter() {
	const [isOpen, setIsOpen] = useState(false);
	const { currency } = useSearch({ from: "/dash/payments" });
	const navigate = useNavigate({ from: "/payments" });
	const { currencies } = useCurrencies();
	const [value, setValue] = useState<string>(
		currencies?.length ? currencies[0] : "USD",
	);
	const ref = useRef(null);

	useOnClickOutside(ref, () => setIsOpen(false));

	if (!currencies) {
		return null;
	}

	return (
		<div
			className={cn(
				"py-1 flex items-center divide-x divide-neutral-200 relative border border-neutral-300 rounded-full cursor-pointer transition-transform duration-200",
				!currency && "border-dashed hover:bg-neutral-50",
			)}
			onClick={(e) => {
				e.preventDefault();

				if (!currency) {
					setIsOpen(true);
					setValue(currencies?.length ? currencies[0] : "USD");
				} else {
					setIsOpen(true);
					setValue(currency);
				}
			}}
		>
			<div className="px-2 flex space-x-1">
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();

						if (!currency) {
							setIsOpen(true);
						} else {
							setIsOpen(false);
							navigate({
								from: "/payments",
								to: "/payments",
								search: ({ currency, ...current }) => ({
									...current,
								}),
							});
						}
					}}
				>
					<CircleXIcon
						className={cn(
							"w-3.5 h-3.5 fill-neutral-600 transition-transform duration-200",
							!currency && "rotate-45",
						)}
					/>
				</button>
				<span className="text-xs font-semibold text-neutral-600">Currency</span>
			</div>
			{currency && (
				<div className="flex px-2">
					<span className="text-xs underline font-semibold text-neutral-600">
						{currency}
					</span>
				</div>
			)}
			{isOpen && (
				<div
					ref={ref}
					className="absolute -left-px top-8 w-64 px-4 py-2 flex flex-col space-y-2 bg-white !border !border-neutral-300 text-neutral-600 rounded-md cursor-default"
				>
					<span className="whitespace-nowrap font-semibold text-sm">
						Filter by currency
					</span>
					<select
						onChange={(e) => {
							setValue(e.target.value);
						}}
						value={value}
					>
						{currencies?.map((currency) => (
							<option key={currency} value={currency}>
								{currency}
							</option>
						))}
						{/* <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            <option value="AUD">AUD</option> */}
					</select>
					<Button
						className="w-full"
						type="button"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();

							if (value === null) {
								return;
							}

							setIsOpen(false);

							navigate({
								from: "/payments",
								to: "/payments",
								search: (current) => ({
									...current,
									currency: value,
								}),
							});
						}}
					>
						Apply
					</Button>
				</div>
			)}
		</div>
	);
}

const statusLabels: Record<PaymentStatus, string> = {
	INCOMPLETE: "Incomplete",
	AUTHORIZED: "Authorized",
	SETTLED: "Settled",
	EXPIRED: "Expired",
	FAILED: "Failed",
	REFUNDED: "Refunded",
	CANCELED: "Canceled",
};

const formValuesSchema = z.object({
	status: paymentStatusWithExpiredSchema.array(),
});

type FormValues = z.infer<typeof formValuesSchema>;

export function StatusFilter() {
	const [isOpen, setIsOpen] = useState(false);
	const { status } = useSearch({ from: "/dash/payments" });
	const navigate = useNavigate({ from: "/payments" });
	const { reset, register, handleSubmit } = useForm<FormValues>({
		resolver: zodResolver(formValuesSchema),
		defaultValues: {
			status: status || [],
		},
	});
	const ref = useRef(null);

	useEffect(() => {
		reset({ status: status || [] });
	}, [status, reset]);

	useOnClickOutside(ref, () => setIsOpen(false));

	return (
		<div
			className={cn(
				"py-1 flex items-center divide-x divide-neutral-200 relative border border-neutral-300 rounded-full cursor-pointer transition-transform duration-200",
				(!status || status.length === 0) && "border-dashed hover:bg-neutral-50",
			)}
			onClick={(e) => {
				e.preventDefault();

				if (!status || status.length === 0) {
					setIsOpen(true);
				} else {
					setIsOpen(true);
				}
			}}
		>
			<div className="px-2 flex space-x-1">
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();

						if (!status || status.length === 0) {
							setIsOpen(true);
						} else {
							setIsOpen(false);
							navigate({
								from: "/payments",
								to: "/payments",
								search: ({ status, ...current }) => ({
									...current,
								}),
							});
						}
					}}
				>
					<CircleXIcon
						className={cn(
							"w-3.5 h-3.5 fill-neutral-600 transition-transform duration-200",
							(!status || status.length === 0) && "rotate-45",
						)}
					/>
				</button>
				<span className="text-xs font-semibold text-neutral-600">Status</span>
			</div>
			{status && status.length > 0 && (
				<div className="flex px-2">
					<span className="text-xs underline font-semibold text-neutral-600">
						{status.join(", ")}
					</span>
				</div>
			)}
			{isOpen && (
				<div
					ref={ref}
					className="absolute -left-px top-8 w-64 px-4 py-2 flex flex-col space-y-2 bg-white !border !border-neutral-300 text-neutral-600 rounded-md cursor-default z-10"
					tabIndex={-1}
				>
					<span className="whitespace-nowrap font-semibold text-sm">
						Filter by status
					</span>
					<form
						className="flex flex-col space-y-2"
						onSubmit={handleSubmit((values) => {
							navigate({
								from: "/payments",
								to: "/payments",
								search: (current) => ({
									...current,
									status: values.status,
								}),
							});
						})}
					>
						<div className="flex flex-col space-y-1">
							{objectEntries(statusLabels)
								// TODO: Remove this temporary filter
								.filter((e) => e[0] !== "REFUNDED" && e[0] !== "EXPIRED")
								.map(([value, label]) => (
									<label
										key={value}
										className="flex items-center space-x-2"
										onClick={(e) => e.stopPropagation()}
									>
										<input
											type="checkbox"
											{...register("status")}
											onClick={(e) => e.stopPropagation()}
											value={value}
										/>
										<span>{label}</span>
									</label>
								))}
						</div>
						<Button
							type="submit"
							className="w-full"
							onClick={(e) => e.stopPropagation()}
						>
							Apply
						</Button>
					</form>
				</div>
			)}
		</div>
	);
}
