import { Link, Outlet, createRoute, redirect } from "@tanstack/react-router";
import { BarsIcon } from "../../assets/bars";
import { CodeIcon } from "../../assets/code";
import { HomeIcon } from "../../assets/home";
import { InflowIcon } from "../../assets/inflow";
import { MoneyChartIcon } from "../../assets/money-chart";
import { QuestionIcon } from "../../assets/question";
import { SettingsIcon } from "../../assets/settings";
import { NotFound } from "../../components/not-found";
import { getUser } from "../../lib/get-user";
import { rootRoute } from "../__root";

export const dashLayout = createRoute({
	id: "dash",
	getParentRoute: () => rootRoute,
	component: DashLayout,
	loader: async ({ context }) => {
		const user = await getUser(context.accessToken);

		if (!user) {
			throw redirect({
				to: "/login",
				search: {
					next: context.location.href,
				},
			});
		}

		if (user?.legalName === null) {
			throw redirect({
				to: "/merchant",
				search: {
					next: context.location.href,
				},
			});
		}

		return {
			user,
		};
	},
	notFoundComponent: () => <NotFound className="h-[calc(100vh_-_60px)]" />,
});

function DashLayout() {
	return (
		<div className="h-screen flex flex-row">
			<div className="w-64 flex-shrink-0 hidden md:block" />
			<div className="w-64 fixed py-6 px-6 top-0 left-0 h-screen hidden md:flex flex-col space-y-12 bg-neutral-50">
				<Link to="/">
					<InflowIcon className="w-12 h-6 fill-black" />
				</Link>
				<div className="flex flex-col space-y-4">
					<Link to="/" className="flex items-center space-x-2">
						<HomeIcon className="w-5 h-5 fill-neutral-700" />
						<span className="text-sm font-medium text-neutral-700">Home</span>
					</Link>
					<Link
						to="/payments"
						search={{
							page: 1,
						}}
						className="flex items-center space-x-2"
					>
						<BarsIcon className="w-5 h-4 fill-neutral-700" />
						<span className="text-sm font-medium text-neutral-700">
							Payments
						</span>
					</Link>
					<Link to="/balance" className="flex items-center space-x-2">
						<MoneyChartIcon className="w-5 h-4 fill-neutral-700" />
						<span className="text-sm font-medium text-neutral-700">
							Balance
						</span>
					</Link>
					<Link to="/developers" className="flex items-center space-x-2">
						<CodeIcon className="w-5 h-4 fill-neutral-700" />
						<span className="text-sm font-medium text-neutral-700">
							Developers
						</span>
					</Link>
				</div>
			</div>
			<div className="w-full flex flex-col">
				<header className="w-full sticky top-0 left-0 py-5 px-8 hidden md:flex justify-end items-center backdrop-blur-sm z-50">
					{/* <div className="-ml-3 px-3 py-2 w-full max-w-sm flex items-center space-x-2 has-[:focus]:bg-neutral-100 hover:bg-neutral-100 transition-colors duration-200 rounded-lg">
            <SearchIcon className="mb-px w-3 h-3 fill-neutral-500" />
            <input
              type="text"
              placeholder="Search..."
              className="w-full px-1 text-sm placeholder-neutral-500 bg-transparent focus:ring-0 outline-none"
            />
          </div> */}
					<div className="flex items-center space-x-6">
						<a
							href="https://inflow-pay.readme.io/reference/introduction?ref=merchant"
							target="_blank"
							rel="noreferrer"
						>
							<QuestionIcon className="w-4 h-4 fill-neutral-700 overflow-visible" />
						</a>
						<Link to="/settings">
							<SettingsIcon className="w-4 h-4 fill-neutral-700 overflow-visible" />
						</Link>
					</div>
				</header>
				<main className="hidden md:block">
					<Outlet />
				</main>
				<main className="flex md:hidden w-full h-screen justify-center items-center">
					<span>
						Please open this page on a larger screen to view the content.
					</span>
				</main>
			</div>
		</div>
	);
}
