export function numberFormatter(
	value: number,
	options?: Intl.NumberFormatOptions,
) {
	return new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		...options,
	}).format(value);
}
