export const countries: Record<string, string> = {
	// Europe
	AT: "Austria",
	BE: "Belgium",
	CZ: "Czech Republic",
	DK: "Denmark",
	EE: "Estonia",
	FI: "Finland",
	FR: "France",
	DE: "Germany",
	HU: "Hungary",
	IE: "Ireland",
	IT: "Italy",
	LV: "Latvia",
	LT: "Lithuania",
	NL: "Netherlands",
	NO: "Norway",
	PL: "Poland",
	PT: "Portugal",
	ES: "Spain",
	SE: "Sweden",
	GB: "United Kingdom",

	// Asia
	CN: "China",
	IN: "India",
	ID: "Indonesia",
	JP: "Japan",
	KR: "South Korea",
	PK: "Pakistan",
	PH: "Philippines",
	SG: "Singapore",
	TH: "Thailand",

	// North America
	CA: "Canada",
	US: "United States",

	// Oceania
	AU: "Australia",
	NZ: "New Zealand",
};
