import { cn } from "../../lib/cn";

type Props = {
	className?: string;
};

export function NoData({ className }: Props) {
	return (
		<div
			className={cn(
				"py-2 px-3 flex flex-col items-center justify-center border-2 border-dashed border-neutral-300 rounded-lg",
				className,
			)}
		>
			<span className="text-neutral-600">No data available</span>
		</div>
	);
}
