import { z } from "zod";
import { bankSchema } from "./bank";

export const paymentStatusSchema = z.enum([
	"INCOMPLETE",
	"AUTHORIZED",
	"SETTLED",
	"EXPIRED",
	"FAILED",
	"REFUNDED",
	"CANCELED",
]);

export type PaymentStatus = z.infer<typeof paymentStatusSchema>;

export const paymentStatusWithExpiredSchema = z.enum([
	...paymentStatusSchema.options,
	"EXPIRED",
]);

export type PaymentStatusWithExpired = z.infer<
	typeof paymentStatusWithExpiredSchema
>;

export const paymentSchema = z.object({
	id: z.string(),
	amount: z.number(),
	customerEmail: z.string().nullable(),
	currency: z.string(),
	products: z.array(
		z.object({
			name: z.string(),
			price: z.number(),
			quantity: z.number(),
		}),
	),
	metadata: z.record(z.any()).optional(),
	status: paymentStatusSchema,
	bank: bankSchema.nullable().optional(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
});

export type Payment = z.infer<typeof paymentSchema>;
