import { getAccessToken } from "@privy-io/react-auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";
import { type Pagination, paginationSchema } from "../schemas/pagination";
import {
	type PaymentStatusWithExpired,
	paymentSchema,
} from "../schemas/payment";

type Filters = {
	status?: PaymentStatusWithExpired[];
	currency?: string;
	page?: number;
	limit?: number;
};

async function getPayments(filters: Filters) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const searchParams = new URLSearchParams();

	searchParams.set("page", filters.page?.toString() ?? "1");
	searchParams.set("limit", filters.limit?.toString() ?? "15");

	if (filters.status) {
		for (const s of filters.status) {
			searchParams.append("status", s);
		}
	}

	if (filters.currency) {
		searchParams.set("currency", filters.currency);
	}

	return fetcher(
		`${
			import.meta.env.VITE_API_URL
		}/dashboard/payments?${searchParams.toString()}`,
		z.object({
			data: paymentSchema.array(),
			meta: paginationSchema,
		}),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function paymentsOptions(filters: Filters) {
	return queryOptions({
		queryKey: [
			"payments",
			filters.status,
			filters.currency,
			filters.page,
			filters.limit,
		],
		queryFn: () => getPayments(filters),
		staleTime: 5 * 1000,
	});
}

export function usePayments(filters: Filters) {
	const { data, ...query } = useQuery(paymentsOptions(filters));

	return {
		payments: data?.data || [],
		pagination: data?.meta || ({} as Pagination),
		...query,
	};
}
