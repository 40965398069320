import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { fetcher } from "../lib/fetcher";
import { balanceSchema } from "../schemas/balance";

async function getBalance() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/balance`,
		balanceSchema,
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function balanceOptions() {
	return queryOptions({
		queryKey: ["balance"],
		queryFn: () => getBalance(),
		staleTime: 10 * 1000,
	});
}

export function useBalance() {
	const { data, ...query } = useQuery(balanceOptions());

	return {
		balance: data,
		...query,
	};
}

export function useSuspenseBalance() {
	const { data, ...query } = useSuspenseQuery(balanceOptions());

	return {
		balance: data,
		...query,
	};
}
