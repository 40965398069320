import { Link } from "@tanstack/react-router";
import { cn } from "../../lib/cn";

type Props = {
	title: string;
	viewAllHref?: string;
	className?: string;
	children: React.ReactNode;
};

export function DataCard({ title, viewAllHref, className, children }: Props) {
	return (
		<div className={cn("w-full flex flex-col space-y-2", className)}>
			<div className="flex justify-between items-center">
				<h3 className="font-semibold">{title}</h3>
				{viewAllHref && (
					<Link
						to={viewAllHref}
						className="text-xs font-medium text-neutral-700 hover:underline"
					>
						View all
					</Link>
				)}
			</div>
			{children}
		</div>
	);
}
