import { useSuspenseQuery } from "@tanstack/react-query";
import { createRoute, useParams } from "@tanstack/react-router";
import dayjs from "dayjs";
import { paymentOptions } from "../../../api/usePayment";
import { NoData } from "../../../components/charts/no-data";
import { StatusPill } from "../../../components/payment/status-pill";
import { numberFormatter } from "../../../lib/number";
import { queryClient } from "../../__root";
import { dashLayout } from "../_layout";

export const dashPaymentRoute = createRoute({
	getParentRoute: () => dashLayout,
	path: "/payment/$id",
	component: PaymentPage,
	loader: async ({ params }) => {
		await queryClient.ensureQueryData(paymentOptions(params.id));
	},
});

function PaymentPage() {
	const { id } = useParams({ from: "/dash/payment/$id" });
	const { data: payment } = useSuspenseQuery(paymentOptions(id));

	if (!payment) {
		return null;
	}

	return (
		<div className="p-8 w-full flex flex-col space-y-12">
			<section className="flex flex-col space-y-4">
				<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
					<h1 className="font-semibold text-2xl">
						{numberFormatter(payment.amount / 100, {
							style: "currency",
							currency: payment.currency,
						})}{" "}
						{payment.currency}
					</h1>
					<StatusPill status={payment.status} />
				</div>
				<div className="flex divide-x divide-neutral-300">
					{payment.initiatedAt && (
						<div className="px-4 first:pl-0 last:pr-0 flex flex-col space-y-1">
							<span className="text-neutral-500 text-sm">Initiated at</span>
							<span>{dayjs(payment.initiatedAt).format("MMM DD, H:mm a")}</span>
						</div>
					)}
					{payment.settledAt && (
						<div className="px-4 first:pl-0 last:pr-0 flex flex-col space-y-1">
							<span className="text-neutral-500 text-sm">Settled at</span>
							<span>{dayjs(payment.settledAt).format("MMM DD, H:mm a")}</span>
						</div>
					)}
					{payment.customerEmail && (
						<div className="px-4 first:pl-0 last:pr-0 flex flex-col space-y-1">
							<span className="text-neutral-500 text-sm">Customer</span>
							<span className="underline">{payment.customerEmail}</span>
						</div>
					)}
					{payment.bank && (
						<div className="px-4 first:pl-0 last:pr-0 flex flex-col space-y-1">
							<span className="text-neutral-500 text-sm">Bank used</span>
							<div className="w-fit flex items-center justify-between space-x-2">
								<img
									src={payment.bank.logo}
									alt={`${payment.bank.name} logo`}
									className="w-5 h-5"
								/>
								<span>{payment.bank.name}</span>
							</div>
						</div>
					)}
				</div>
			</section>
			<section className="flex flex-col space-y-4">
				<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
					<h2 className="font-semibold text-2xl">Timeline</h2>
				</div>
				<div className="flex flex-col space-y-3">
					{payment.timeline.map((t) => (
						<div key={t.status} className="flex flex-col space-y-1">
							<span className="text-neutral-700">{t.label}</span>
							<span className="text-neutral-500 text-sm">
								{dayjs(t.timestamp).format("MMM DD, H:mm a")}
							</span>
						</div>
					))}
				</div>
			</section>
			<section className="flex flex-col space-y-4">
				<div className="flex space-x-2 items-center border-b border-neutral-300 pb-2">
					<h2 className="font-semibold text-2xl">Payment details</h2>
				</div>
				{payment.onRampDestination ? (
					<div className="grid grid-cols-1 xl:grid-cols-2 gap-y-6 xl:gap-0">
						<div className="flex flex-col space-y-2">
							<div className="flex">
								<span className="w-40 text-neutral-500">Amount</span>
								<span>
									{numberFormatter(payment.onRampDestination.initialAmount, {
										style: "currency",
										currency: payment.currency,
									})}
								</span>
							</div>
							<div className="flex">
								<span className="w-40 text-neutral-500">Fee (1%)</span>
								<span>
									{numberFormatter(payment.onRampDestination.developerFee, {
										style: "currency",
										currency: payment.currency,
									})}
								</span>
							</div>
							<div className="flex">
								<span className="w-40 text-neutral-500">Subtotal</span>
								<span>
									{numberFormatter(payment.onRampDestination.subtotalAmount, {
										style: "currency",
										currency: payment.currency,
									})}
								</span>
							</div>
							<div className="flex">
								<span className="w-40 text-neutral-500">Rate</span>
								<div className="flex space-x-2 uppercase">
									<span>1 {payment.currency}</span>
									<span>{">"}</span>
									<span>
										{payment.onRampDestination.exchangeRate}{" "}
										{payment.onRampDestination.currency}
									</span>
								</div>
							</div>
							<div className="flex">
								<span className="w-40 text-neutral-500">Net</span>
								<span className="uppercase">
									{payment.onRampDestination.finalAmount}{" "}
									{payment.onRampDestination.currency}
								</span>
							</div>
						</div>
						<div className="flex flex-col space-y-2">
							<div className="flex">
								<span className="w-40 text-neutral-500">Status</span>
								<StatusPill status={payment.status} />
							</div>
							<div className="flex">
								<span className="w-40 text-neutral-500">Type</span>
								<span>One-time</span>
							</div>
							<div className="flex">
								<span className="w-40 text-neutral-500">Bank name</span>
								<span>{payment.bank?.name}</span>
							</div>
							{payment.bank?.paymentRail && (
								<div className="flex">
									<span className="w-40 text-neutral-500">Payment rail</span>
									<span className="uppercase">{payment.bank?.paymentRail}</span>
								</div>
							)}
							{payment.bank?.iban && (
								<div className="flex">
									<span className="w-40 text-neutral-500">IBAN</span>
									<span>{payment.bank?.iban}</span>
								</div>
							)}
						</div>
					</div>
				) : (
					<NoData className="m-4" />
				)}
			</section>
		</div>
	);
}
