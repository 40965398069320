import { getAccessToken } from "@privy-io/react-auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import type { Pagination } from "../schemas/pagination";
import type { Payout } from "../schemas/payout";

type Filters = {
	page?: number;
	limit?: number;
};

async function getPayouts(filters: Filters) {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	const searchParams = new URLSearchParams();

	searchParams.set("page", filters.page?.toString() ?? "1");
	searchParams.set("limit", filters.limit?.toString() ?? "15");

	return {
		data: [
			{
				id: "1",
				amount: 131.42,
				status: "PROCESSING",
				createdAt: new Date(),
				updatedAt: new Date(),
			},
			{
				id: "2",
				amount: 211.82,
				status: "SUCCESSFUL",
				createdAt: new Date(new Date().setDate(new Date().getDate() - 7)),
				updatedAt: new Date(new Date().setDate(new Date().getDate() - 7)),
			},
			{
				id: "3",
				amount: 189.38,
				status: "SUCCESSFUL",
				createdAt: new Date(new Date().setDate(new Date().getDate() - 14)),
				updatedAt: new Date(new Date().setDate(new Date().getDate() - 14)),
			},
			{
				id: "4",
				amount: 171.11,
				status: "SUCCESSFUL",
				createdAt: new Date(new Date().setDate(new Date().getDate() - 21)),
				updatedAt: new Date(new Date().setDate(new Date().getDate() - 21)),
			},
			{
				id: "5",
				amount: 199.15,
				status: "SUCCESSFUL",
				createdAt: new Date(new Date().setDate(new Date().getDate() - 28)),
				updatedAt: new Date(new Date().setDate(new Date().getDate() - 28)),
			},
		] as Payout[],
		meta: {
			total: 1,
			lastPage: 1,
			currentPage: 1,
			perPage: 15,
			prev: null,
			next: null,
		},
	};

	// return fetcher(
	//   `${
	//     import.meta.env.VITE_API_URL
	//   }/dashboard/payouts?${searchParams.toString()}`,
	//   z.object({
	//     data: payoutSchema.array(),
	//     meta: paginationSchema,
	//   }),
	//   {
	//     headers: {
	//       "Content-Type": "application/json",
	//       Authorization: `Bearer ${token}`,
	//     },
	//   }
	// );
}

export function payoutsOptions(filters: Filters) {
	return queryOptions({
		queryKey: ["payouts", filters.page, filters.limit],
		queryFn: () => getPayouts(filters),
		staleTime: 5 * 1000,
	});
}

export function usePayouts(filters: Filters) {
	const { data, ...query } = useQuery(payoutsOptions(filters));

	return {
		payouts: data?.data || [],
		pagination: data?.meta || ({} as Pagination),
		...query,
	};
}
