import { getAccessToken } from "@privy-io/react-auth";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";

async function getCurrencies() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/currencies`,
		z.string().array(),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function paymentOptions() {
	return queryOptions({
		queryKey: ["payment"],
		queryFn: () => getCurrencies(),
		staleTime: 5 * 1000,
	});
}

export function useCurrencies() {
	const { data, ...query } = useQuery(paymentOptions());

	return {
		currencies: data,
		...query,
	};
}
