import { merchantSchema } from "../schemas/merchant";
import { fetcher } from "./fetcher";

export async function getUser(token: string | null) {
	if (!token) {
		return null;
	}

	try {
		const user = await fetcher(
			`${import.meta.env.VITE_API_URL}/dashboard/me`,
			merchantSchema,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			},
		);

		return user;
	} catch (_e) {
		return null;
	}
}
