import { z } from "zod";

export const withdrawSchema = z.object({
	id: z.string(),
	amount: z.number(),
	currency: z.string(),
	status: z.enum([
		"INITIATED",
		"SENT",
		"PROCESSING",
		"SUBMITTED",
		"COMPLETED",
		"CANCELED",
		"FAILED",
	]),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
	userId: z.string(),
});

export type Withdraw = z.infer<typeof withdrawSchema>;
