import { getAccessToken } from "@privy-io/react-auth";
import {
	queryOptions,
	useQuery,
	useSuspenseQuery,
} from "@tanstack/react-query";
import { z } from "zod";
import { fetcher } from "../lib/fetcher";

async function getSupportedCountries() {
	const token = await getAccessToken();

	if (!token) {
		throw new Error("Unauthorized");
	}

	return fetcher(
		`${import.meta.env.VITE_API_URL}/dashboard/supported-countries`,
		z.string().array(),
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function supportedCountriesOptions() {
	return queryOptions({
		queryKey: ["supported-countries"],
		queryFn: () => getSupportedCountries(),
		staleTime: 10 * 1000,
	});
}

export function useSupportedCountries() {
	const { data, ...query } = useQuery(supportedCountriesOptions());

	return {
		supportedCountries: data,
		...query,
	};
}

export function useSuspenseSupportedCountries() {
	const { data, ...query } = useSuspenseQuery(supportedCountriesOptions());

	return {
		supportedCountries: data,
		...query,
	};
}
